import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Function to get language from IP
async function getLanguageFromIP() {
  try {
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();
    const countryCode = data.country_code.toLowerCase();
    
    const countryToLang = {
      cn: 'zh', hk: 'zh', tw: 'zh', mo: 'zh', sg: 'zh',
      jp: 'ja',
      us: 'en', gb: 'en', au: 'en', ca: 'en', nz: 'en',
    };
    
    return countryToLang[countryCode] || 'en';
  } catch (error) {
    console.error('Error detecting language from IP:', error);
    return 'en';
  }
}

const ipLanguageDetector = {
  name: 'ipapi',
  lookup: async () => {
    return await getLanguageFromIP();
  },
  cacheUserLanguage: (lng: string) => {
    localStorage.setItem('i18nextLng', lng);
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          welcome_message: '{{domain}}',
          premium_domain: 'Premium Domain',
          domain_waiting: 'This premium domain is waiting for the right owner',
          make_offer: 'Make an Offer',
          your_offer: 'Your Offer (USD)',
          email: 'Email',
          message: 'Message (Optional)',
          submit_offer: 'Submit Offer',
          verify_human: 'Please verify you are human',
          offer_submitted: 'Offer submitted!',
          will_contact: 'We will contact you via email soon.',
          refresh: 'Refresh',
          enter_captcha: 'Enter verification code',
          captcha_error: 'Incorrect code, please try again',
          switch_to_light: 'Switch to light mode',
          switch_to_dark: 'Switch to dark mode',
          domain_description: 'A premium domain name that enhances your brand visibility and market presence.',
          offer_description: 'Please fill out the form below to submit your offer.',
          why_premium: 'Why Choose a Premium Domain?',
          premium_description: 'In the digital age, a premium domain is more than just a web address - it\'s a cornerstone of your brand value.',
          all_rights_reserved: 'All rights reserved.',
          global_visibility: 'Global Visibility',
          global_visibility_desc: 'Reach audiences worldwide with a memorable domain name.',
          brand_value: 'Brand Value',
          brand_value_desc: 'Establish a strong online presence with a premium domain.',
          business_asset: 'Business Asset',
          business_asset_desc: 'Invest in a valuable digital asset for your business.',
          market_advantage: 'Market Advantage',
          market_advantage_desc: 'Stand out from competitors with a premium domain name.',
          switch_language: 'Switch language',
          current_domain: 'Current Domain',
          make_offer_for: 'Make an Offer for',
          enter_offer_amount: 'Please enter an offer amount',
          enter_your_email: 'Please enter a valid email address',
          enter_your_message: 'Enter your message (optional)',
          submitting: 'Submitting...',
          please_verify: 'Please Verify',
          please_complete_verification: 'Please complete the verification before submitting',
          verification_success: 'Verification Successful',
          slide_to_verify: 'Slide to verify',
          error_occurred: 'An Error Occurred',
          failed_to_send: 'Failed to send offer. Please try again.',
        }
      },
      zh: {
        translation: {
          welcome_message: '{{domain}}',
          premium_domain: '优质域名',
          domain_waiting: '这个高级域名正在等待合适的主人',
          make_offer: '立即报价',
          your_offer: '您的报价 (USD)',
          email: '电子邮箱',
          message: '留言 (可选)',
          submit_offer: '提交报价',
          verify_human: '请完成人机验证',
          offer_submitted: '报价已提交！',
          will_contact: '我们会尽快通过邮件与您联系。',
          refresh: '刷新',
          enter_captcha: '请输入验证码',
          captcha_error: '验证码错误，请重试',
          switch_to_light: '切换到明亮模式',
          switch_to_dark: '切换到暗黑模式',
          domain_description: '优质域名能为您的品牌带来持久的竞争优势和市场价值。',
          offer_description: '请填写以下表单提交您的报价。',
          why_premium: '为什么选择优质域名？',
          premium_description: '在数字时代，优质域名不仅是网络地址，更是品牌价值的核心组成部分。',
          all_rights_reserved: '版权所有。',
          global_visibility: '全球可见性',
          global_visibility_desc: '通过一个令人难忘的域名触达全球受众。',
          brand_value: '品牌价值',
          brand_value_desc: '通过优质域名建立强大的在线形象。',
          business_asset: '商业资产',
          business_asset_desc: '为您的企业投资有价值的数字资产。',
          market_advantage: '市场优势',
          market_advantage_desc: '以优质域名从竞争对手中脱颖而出。',
          switch_language: '切换语言',
          current_domain: '当前域名',
          make_offer_for: '为以下域名报价',
          enter_offer_amount: '请输入报价金额',
          enter_your_email: '输入您的邮箱地址',
          enter_your_message: '输入留言信息（可选）',
          submitting: '提交中...',
          please_verify: '请验证',
          please_complete_verification: '提交前请完成验证',
          verification_success: '验证成功',
          slide_to_verify: '滑动验证',
          error_occurred: '发生错误',
          failed_to_send: '发送报价失败，请重试。',
        }
      },
      ja: {
        translation: {
          welcome_message: '{{domain}}',
          premium_domain: 'プレミアムドメイン',
          domain_waiting: 'このプレミアムドメインは適切なオーナーを待っています',
          make_offer: '今すぐ申し込む',
          your_offer: 'お見積り (USD)',
          email: 'メールアドレス',
          message: 'メッセージ (任意)',
          submit_offer: '申し込みを送信',
          verify_human: '人間であることを確認してください',
          offer_submitted: '申し込みが送信されました！',
          will_contact: '近日中にメールにてご連絡いたします。',
          refresh: '更新',
          enter_captcha: '認証コードを入力',
          captcha_error: 'コードが正しくありません',
          switch_to_light: 'ライトモードに切り替え',
          switch_to_dark: 'ダークモードに切り替え',
          domain_description: 'プレミアムドメインは、ブランドの価値と市場での存在感を高めます。',
          offer_description: '以下のフォームに必要事項を入力してください。',
          why_premium: 'プレミアムドメインを選ぶ理由',
          premium_description: 'デジタル時代において、プレミアムドメインはウェブアドレス以上の、ブランド価値の重要な要素です。',
          all_rights_reserved: '全著作権所有。',
          global_visibility: 'グローバルな可視性',
          global_visibility_desc: '印象的なドメイン名で世界中の視聴者にリーチ。',
          brand_value: 'ブランド価値',
          brand_value_desc: 'プレミアムドメインで強力なオンラインプレゼンスを確立。',
          business_asset: 'ビジネス資産',
          business_asset_desc: 'ビジネスのための価値ある デジタル資産に投資。',
          market_advantage: '市場優位性',
          market_advantage_desc: 'プレミアムドメインで競合他社と差別化。',
          switch_language: '言語を切り替える',
          current_domain: '現在のドメイン',
          make_offer_for: '次のドメインに対する申し込み',
          enter_offer_amount: '希望金額を入力',
          enter_your_email: 'メールアドレスを入力',
          enter_your_message: 'メッセージを入力（任意）',
          submitting: '送信中...',
          please_verify: '確認してください',
          please_complete_verification: '送信前に確認を完了してください',
          verification_success: '確認完了',
          slide_to_verify: 'スライドして確認',
          error_occurred: 'エラーが発生しました',
          failed_to_send: '送信に失敗しました。もう一度お試しください。',
        }
      }
    },
    fallbackLng: 'en',
    detection: {
      order: ['ipapi', 'localStorage', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
