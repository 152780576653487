import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./pages/Index";
import { useState, useEffect } from "react";

function App() {
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
        meta: {
          onError: (error: Error) => {
            console.error('Query error:', error);
          },
        }
      },
      mutations: {
        meta: {
          onError: (error: Error) => {
            console.error('Mutation error:', error);
          },
        }
      },
    },
  }));

  useEffect(() => {
    console.log('App mounted');
    // Log environment variables availability (not the values)
    console.log('Environment variables check:', {
      SMTP_HOST: !!import.meta.env.VITE_SMTP_HOST,
      SMTP_PORT: !!import.meta.env.VITE_SMTP_PORT,
      SMTP_USERNAME: !!import.meta.env.VITE_SMTP_USERNAME,
      SMTP_PASSWORD: !!import.meta.env.VITE_SMTP_PASSWORD,
      FROM_EMAIL: !!import.meta.env.VITE_FROM_EMAIL,
      OWNER_EMAIL: !!import.meta.env.VITE_OWNER_EMAIL,
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <TooltipProvider>
        <Toaster />
        <Sonner />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Index />} />
          </Routes>
        </BrowserRouter>
      </TooltipProvider>
    </QueryClientProvider>
  );
}

export default App;