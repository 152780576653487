
import { useEffect, useState } from "react";
import { Globe, Sun, Moon, Languages } from "lucide-react";
import { useTranslation } from "react-i18next";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

export const DomainDisplay = () => {
  const [isDark, setIsDark] = useState(false);
  const [domain, setDomain] = useState("");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const darkModePreference = window.matchMedia('(prefers-color-scheme: dark)').matches;
    setIsDark(darkModePreference);
    document.documentElement.classList.toggle('dark', darkModePreference);

    // Get current domain
    const hostname = window.location.hostname;
    const cleanDomain = hostname.replace(/^(https?:\/\/)?(www\.)?/, "");
    setDomain(cleanDomain);
  }, []);

  const toggleTheme = () => {
    const newIsDark = !isDark;
    setIsDark(newIsDark);
    document.documentElement.classList.toggle('dark');
  };

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    document.documentElement.setAttribute('lang', lang);
  };

  const languages = [
    { code: 'en', name: 'English' },
    { code: 'zh', name: '中文' },
    { code: 'ja', name: '日本語' }
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center space-x-4">
          <div className="inline-flex items-center justify-center p-2 rounded-full bg-white/5 border border-white/10 hover:bg-white/10 transition-colors animate-fade-up">
            <Globe className="w-5 h-5 text-domain-light mr-2 animate-pulse" />
            <span className="text-sm font-bold text-domain-dark dark:text-white">{t('premium_domain')}</span>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <DropdownMenu>
            <DropdownMenuTrigger className="inline-flex items-center justify-center p-2 rounded-full bg-white/5 border border-white/10 hover:bg-white/10 transition-colors animate-fade-up" style={{ animationDelay: "200ms" }}>
              <Languages className="w-5 h-5 text-domain-light" />
              <span className="ml-2 text-sm font-medium text-domain-dark dark:text-white">
                {languages.find(lang => lang.code === i18n.language)?.name || 'English'}
              </span>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {languages.map((lang) => (
                <DropdownMenuItem
                  key={lang.code}
                  onClick={() => changeLanguage(lang.code)}
                  className={`cursor-pointer ${i18n.language === lang.code ? 'text-domain-light' : ''}`}
                >
                  {lang.name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
          <button
            onClick={toggleTheme}
            className="p-2 rounded-full bg-white/5 border border-white/10 hover:bg-white/10 transition-colors animate-fade-up"
            style={{ animationDelay: "400ms" }}
            aria-label={isDark ? t('switch_to_light') : t('switch_to_dark')}
          >
            {isDark ? (
              <Sun className="w-5 h-5 text-yellow-400" />
            ) : (
              <Moon className="w-5 h-5 text-domain-dark" />
            )}
          </button>
        </div>
      </div>
      
      <div className="text-center lg:text-left py-12 lg:py-20">
        <h1 className="text-6xl lg:text-8xl font-heading font-extrabold mb-6 animate-fade-up" style={{ animationDelay: "600ms" }}>
          <span className="gradient-text animate-gradient">
            {t('welcome_message', { domain: domain })}
          </span>
        </h1>
        <p className="text-xl lg:text-3xl font-medium max-w-3xl mx-auto lg:mx-0 text-domain-dark dark:text-gray-300 animate-fade-up" style={{ animationDelay: "800ms" }}>
          {t('domain_waiting')}
        </p>
      </div>
    </div>
  );
};
