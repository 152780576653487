
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/hooks/use-toast";
import { useTranslation } from "react-i18next";
import { Label } from "@/components/ui/label";
import { Card } from "@/components/ui/card";
import { DollarSign, Mail, MessageSquare } from "lucide-react";

interface OfferFormProps {
  domain: string;
}

interface OfferFormData {
  amount: string;
  email: string;
  message: string;
}

export function OfferForm({ domain }: OfferFormProps) {
  const { register, handleSubmit, formState: { errors }, watch, reset } = useForm<OfferFormData>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();
  const { t } = useTranslation();

  const amount = watch("amount");
  const email = watch("email");

  const onSubmit = async (data: OfferFormData) => {
    try {
      setIsSubmitting(true);
      
      const formData = {
        offer_amount: parseFloat(data.amount),
        email: data.email.trim(),
        message: data.message?.trim() || '',
        domain: domain
      };

      const response = await fetch('/api/send-offer-notification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      const result = await response.json();

      if (!response.ok || !result.success) {
        throw new Error(result.message || 'Failed to submit offer');
      }

      toast({
        title: "Success!",
        description: "Your offer has been submitted successfully."
      });

      reset(); // 重置表单
    } catch (error: any) {
      console.error('Submission error:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to submit offer",
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card className="p-6 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div className="space-y-2">
          <Label htmlFor="amount" className="text-sm font-medium">
            {t('your_offer')}
          </Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-muted-foreground" />
            <Input
              id="amount"
              type="number"
              placeholder="0.00"
              step="0.01"
              min="1"
              {...register("amount", { 
                required: "Please enter an offer amount",
                min: { value: 1, message: "Minimum offer amount is $1" }
              })}
              className="pl-10 bg-background"
            />
          </div>
          {errors.amount && (
            <p className="text-sm text-destructive">{errors.amount.message}</p>
          )}
        </div>

        <div className="space-y-2">
          <Label htmlFor="email" className="text-sm font-medium">
            {t('email')}
          </Label>
          <div className="relative">
            <Mail className="absolute left-3 top-2.5 h-5 w-5 text-muted-foreground" />
            <Input
              id="email"
              type="email"
              {...register("email", { 
                required: "Please enter your email",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Please enter a valid email"
                }
              })}
              className="pl-10 bg-background"
              placeholder="you@example.com"
            />
          </div>
          {errors.email && (
            <p className="text-sm text-destructive">{errors.email.message}</p>
          )}
        </div>

        <div className="space-y-2">
          <Label htmlFor="message" className="text-sm font-medium">
            {t('message')}
          </Label>
          <div className="relative">
            <MessageSquare className="absolute left-3 top-2.5 h-5 w-5 text-muted-foreground" />
            <Textarea
              id="message"
              {...register("message")}
              className="pl-10 min-h-[100px] bg-background resize-none"
              placeholder={t('enter_your_message')}
            />
          </div>
        </div>

        <Button 
          type="submit" 
          className="w-full bg-domain-light hover:bg-purple-500 transition-all duration-300"
          disabled={isSubmitting || !amount || !email}
        >
          {isSubmitting ? "Submitting..." : "Submit Offer"}
        </Button>
      </form>
    </Card>
  );
}
