import { Building2, Globe2, Rocket, Trophy } from "lucide-react";
import { useTranslation } from "react-i18next";

export const ValueProposition = () => {
  const { t } = useTranslation();
  
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-16 animate-fade-up opacity-0" style={{ animationDelay: "0.6s" }}>
      {[
        {
          icon: <Globe2 className="w-8 h-8 text-domain-light" />,
          title: t('global_visibility'),
          description: t('global_visibility_desc')
        },
        {
          icon: <Trophy className="w-8 h-8 text-domain-light" />,
          title: t('brand_value'),
          description: t('brand_value_desc')
        },
        {
          icon: <Building2 className="w-8 h-8 text-domain-light" />,
          title: t('business_asset'),
          description: t('business_asset_desc')
        },
        {
          icon: <Rocket className="w-8 h-8 text-domain-light" />,
          title: t('market_advantage'),
          description: t('market_advantage_desc')
        }
      ].map((item, index) => (
        <div 
          key={index}
          className="p-6 rounded-xl bg-white/5 backdrop-blur-sm border border-white/10 hover:border-domain-light/50 transition-all duration-300"
        >
          <div className="flex flex-col items-center text-center space-y-4">
            {item.icon}
            <h3 className="text-xl font-heading font-bold text-domain-dark dark:text-white">{item.title}</h3>
            <p className="text-domain-dark/80 dark:text-gray-400 font-medium">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};