
import { DomainDisplay } from "@/components/DomainDisplay";
import { OfferForm } from "@/components/OfferForm";
import { ValueProposition } from "@/components/ValueProposition";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { ArrowRight } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Index = () => {
  const [domain, setDomain] = useState("");
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const hostname = window.location.hostname;
    const cleanDomain = hostname.replace(/^(https?:\/\/)?(www\.)?/, "");
    setDomain(cleanDomain);
  }, []);

  return (
    <div className="min-h-screen bg-background text-foreground">
      <div 
        className="fixed inset-0 w-full h-full bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] animate-gradient"
        style={{
          background: "radial-gradient(circle at 50% -20%, rgba(157, 127, 255, 0.15), rgba(0, 0, 0, 0) 70%), radial-gradient(circle at 0% 0%, rgba(157, 127, 255, 0.1), rgba(0, 0, 0, 0) 50%)",
          zIndex: 0
        }}
      />
      
      <div className="relative container mx-auto px-4 py-12 max-w-7xl min-h-screen flex flex-col">
        <div className="flex-grow">
          <DomainDisplay />
          
          <div className="mt-8 max-w-2xl mx-auto text-center animate-fade-up opacity-0" style={{ animationDelay: "0.4s" }}>
            <p className="text-xl text-foreground/80 leading-relaxed animate-fade-up" style={{ animationDelay: "1s" }}>
              {t('domain_description')}
            </p>
            
            <Dialog>
              <DialogTrigger asChild>
                <Button 
                  className="mt-8 relative overflow-hidden group bg-domain-light hover:bg-purple-500 transition-all duration-300 text-lg px-8 py-6 h-auto animate-fade-up hover:scale-105"
                  style={{ animationDelay: "1.2s" }}
                >
                  <span className="relative z-10 flex items-center">
                    <span className="shimmer-text mr-2">{t('premium_domain')}</span>
                    {t('make_offer')}
                    <ArrowRight className="ml-2 group-hover:translate-x-1 transition-transform" />
                  </span>
                  <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-pink-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                </Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-[500px] bg-domain-dark border-white/10">
                <DialogHeader>
                  <DialogTitle className="text-2xl font-heading">
                    <span className="gradient-text animate-gradient">
                      {t('make_offer_for')} {domain}
                    </span>
                  </DialogTitle>
                  <DialogDescription className="text-gray-400 animate-fade-up">
                    {t('offer_description')}
                  </DialogDescription>
                </DialogHeader>
                <OfferForm domain={domain} />
              </DialogContent>
            </Dialog>
          </div>

          <ValueProposition />
          
          <div className="mt-16 text-center animate-fade-up opacity-0" style={{ animationDelay: "0.8s" }}>
            <h2 className="text-3xl font-heading font-bold gradient-text mb-4 animate-gradient">
              {t('why_premium')}
            </h2>
            <p className="text-foreground/80 max-w-3xl mx-auto leading-relaxed animate-fade-up" style={{ animationDelay: "1.4s" }}>
              {t('premium_description')}
            </p>
          </div>
        </div>

        <footer className="mt-20 py-6 border-t border-foreground/10 text-center text-sm text-foreground/60 animate-fade-up" style={{ animationDelay: "1.6s" }}>
          <p>© {currentYear} NIC.BN Ltd. {t('all_rights_reserved')}</p>
        </footer>
      </div>
    </div>
  );
};

export default Index;
